import axios from "axios";
import { AUTH_ENDPOINT } from "../config";

export default {
  login: function (id, otp) {
    if (id === "" || otp === "") {
      alert("Fill the details completely");
      return new Promise((resolve) => {
        resolve("ERR:BAD_REQUEST");
      });
    } else {
      return new Promise((resolve) => {
        axios
          .post(AUTH_ENDPOINT + "/loginOTP", {
            phone: "+91" + id,
            otp,
          })
          .then((res) => {
            res.data.profiles = res.data.profiles || [];
            const profile =
              res.data.profiles.length > 0 ? res.data.profiles[0] : {};
            const token =
              res.data.profiles.length > 0
                ? res.data.profiles[0].token
                : res.data.jwt;
            const newUser = res.data.profiles.length > 0 ? false : true;
            resolve({ status: 200, data: { profile, token, newUser } });
            axios.defaults.headers.common["token"] = token;
          })
          .catch(() => {
            resolve("ERR:UNK");
          });
      });
    }
  },
  loginJWT: function (jwt) {
    return new Promise((resolve) => {
      axios
        .post(AUTH_ENDPOINT + "/loginJWT", {
          jwt,
        })
        .then((res) => {
          res.data.profiles = res.data.profiles || [];
          const profile =
            res.data.profiles.length > 0 ? res.data.profiles[0] : {};
          const token =
            res.data.profiles.length > 0
              ? res.data.profiles[0].token
              : res.data.jwt;
          const newUser = res.data.profiles.length > 0 ? false : true;
          resolve({ status: 200, data: { profile, token, newUser } });
          axios.defaults.headers.common["token"] = token;
        })
        .catch(() => {
          resolve("ERR:UNK");
        });
    });
  },
  isLoggedIn: function () {
    const token = localStorage.getItem("AUTH_TOKEN");
    axios.defaults.headers.common["token"] = token;
    return !!(token && token !== "");
  },
  sendOTP: async function (phone) {
    if (phone === "") {
      return "ERR:NOPHONE";
    } else {
      return new Promise((resolve) => {
        axios
          .post(AUTH_ENDPOINT + "/sendOTP", {
            phone: "+91" + phone,
            isShort: true,
          })
          .then(() => {
            resolve({ status: 200, msg: "SUCCESS" });
          })
          .catch(() => {
            resolve({ status: 500, msg: "ERR:UNK" });
          });
      });
    }
  },
  logout: function (token) {
    axios
      .put(
        AUTH_ENDPOINT + "/logout",
        {},
        {
          headers: {
            token: token || localStorage.getItem("AUTH_TOKEN"),
          },
        }
      )
      .then(() => {
        localStorage.clear();
        delete axios.defaults.headers.common["token"];
      })
      .catch(() => {
        localStorage.clear();
        delete axios.defaults.headers.common["token"];
      });
  },
  register: async function (name, gender, age, token) {
    return new Promise((resolve) => {
      axios
        .post(AUTH_ENDPOINT + "/register", {
          name,
          gender,
          age,
          phone: token,
          city: "FarmakoHQ",
        })
        .then((res) => {
          const token = res.data.profiles[0].token;
          axios.defaults.headers.common["token"] = token;

          resolve({
            status: 200,
            data: {
              profile: res.data.profiles[0],
              token,
            },
          });
        })
        .catch(() => {
          resolve({ status: 500, msg: "ERR:UNK" });
        });
    });
  },
  updateName: async function (name) {
    return axios.post(
      AUTH_ENDPOINT + "/me",
      { name },
      {
        headers: {
          token: localStorage.getItem("AUTH_TOKEN"),
        },
      }
    );
  },
  getUserDetails: async function () {
    return axios.get(AUTH_ENDPOINT + "/me", {
      headers: {
        token: localStorage.getItem("AUTH_TOKEN"),
      },
    });
  },
};
