import { toast } from "react-toastify";
import * as actions from "./actionTypes";
import APIClient from "../../APIClient";

export const sendOtp = (phone: string) => async (dispatch: any) => {
  try {
    const res = await APIClient.users.sendOTP(phone);
    toast("OTP Sent!");
    dispatch({ type: actions.SEND_OTP, payload: phone });
    return res;
  } catch (e) {
    return { status: 500, message: "Error sending OTP!" };
  }
};

export const loginUser =
  (phone: string, otp: string) => async (dispatch: any) => {
    try {
      const res = await APIClient.users.login(phone, otp);
      dispatch({
        type: actions.LOGIN_USER,
        payload: res.data,
      });
      localStorage.setItem("AUTH_TOKEN", res.data.token);
      return res;
    } catch (e) {
      return { status: 500, message: "Error Login!" };
    }
  };

export const loginUserJWT = (jwt: string) => async (dispatch: any) => {
  try {
    const res = await APIClient.users.loginJWT(jwt);
    dispatch({
      type: actions.LOGIN_USER,
      payload: res.data,
    });
    localStorage.setItem("AUTH_TOKEN", res.data.token);
    return res.data;
  } catch (e) {
    return { status: 500, message: "Error Login!" };
  }
};

export const logoutUser = () => async (dispatch: any) => {
  APIClient.users.logout();
  dispatch({
    type: actions.LOGOUT_USER,
  });
};

export const register =
  (name: string, gender: string, age: number, token: string) =>
  async (dispatch: any) => {
    try {
      const res = await APIClient.users.register(name, gender[0], age, token);
      dispatch({
        type: actions.REGISTER,
        payload: res.data,
      });
      localStorage.setItem("AUTH_TOKEN", res.data.token);
      return res;
    } catch (e) {
      return { status: 500, message: "Error Register!" };
    }
  };

export const getUserDetails = () => async (dispatch: any) => {
  try {
    const res = await APIClient.users.getUserDetails();
    dispatch({ type: actions.UPDATE_USER, payload: res.data });
    return res;
  } catch (e) {
    if ((e as any).response && (e as any).response.status === 401) {
      dispatch({ type: actions.LOGOUT_USER });
    }
    return { status: 500, message: "Error updating user!" };
  }
};

export const updateUser = (name: string) => async (dispatch: any) => {
  try {
    const res = await APIClient.users.updateName(name);
    dispatch({ type: actions.UPDATE_USER, payload: res.data });
    toast("Name updated!");
    return res;
  } catch (e) {
    toast("An error occured while updating!");
    return { status: 500, message: "Error updating user!" };
  }
};
