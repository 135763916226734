import { lazy, Suspense, useEffect } from "react";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/tailwind.css";
import "@farmako/fractal/dist/index.css";
import { Switch, Route, useHistory } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { bindActionCreators } from "redux";
import * as actionCreators from "@actions/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@redux/store";
import PopupClose from "@components/widgets/PopupClose";

import { getUserDetails } from "@actions/auth";
import { useLocation } from "react-router-dom";

import cross from "@images/cross-white.svg";

// lazy screens
const StoriesScreen = lazy(() => import("@components/screens/StoriesScreen"));
const LoginScreen = lazy(() => import("@components/screens/LoginScreen"));
const ViewReportsScreen = lazy(
  () => import("@components/screens/ViewReportsScreen")
);

function App() {
  const profile = useSelector((state: any) => state.profile.profiles);
  const isLoggedIn = useSelector((state: any) => state.profile.isLoggedIn);
  const history = useHistory();

  const dispatch: AppDispatch = useDispatch();
  const AC = bindActionCreators(actionCreators, dispatch);
  const { clearUploadedFiles } = AC;
  const location = useLocation();

  useEffect(() => {
    console.log("Hope that Anurag will be superman");
    clearUploadedFiles();
  }, []);

  useEffect(() => {
    mixpanel.init("8fb4506793ebda051e7d2f83cf18673f", {
      debug: true,
      ignore_dnt: true,
    });
    mixpanel.track("medical history screen opened", {});
  }, []);

  useEffect(() => {
    (async () => {
      await dispatch(getUserDetails());
    })();
  }, [profile && profile.token]);

  useEffect(() => {
    const jwt = new URLSearchParams(location.search).get("jwt");
    if (!jwt) {
      if (navigator.onLine) {
        if (!isLoggedIn) history.push("/login");
      }
    }
  }, [isLoggedIn]);

  const ToastCloseBtn = ({ closeToast }: any) => (
    <img src={cross} alt="x" onClick={closeToast} className="mr-4" />
  );

  return (
    <>
      <PopupClose />

      <ToastContainer
        theme="dark"
        position="bottom-center"
        autoClose={3000}
        closeOnClick
        hideProgressBar
        pauseOnFocusLoss={false}
        closeButton={ToastCloseBtn}
        transition={Flip}
      />
      <Suspense
        fallback={
          <div className="w-screen h-screen flex justify-center items-center">
            <div className="w-12 h-12 border-4 border-t-4 border-t-vibra-100 rounded-full animate-spin"></div>
          </div>
        }
      >
        <Switch>
          <Route exact path="/view-report" component={StoriesScreen} />
          <Route exact path="/login" component={LoginScreen} />
          <Route exact path="/" render={() => <ViewReportsScreen />} />
        </Switch>
      </Suspense>
    </>
  );
}

export default App;
