import { User, Mode, MimeType, FileInterface } from "../data/interfaces";
import axios from "axios";
import { GRPC_ENDPOINT } from "../../config";

export const retrieveDataUsingJwt = async (jwt: string) => {
  const data = {
    jwt,
  };
  const url = `${GRPC_ENDPOINT}:3030/docinterface.DocAPI/getTimeline`;

  const config = {
    headers: {
      client: "Server",
    },
  };

  try {
    const response = await axios.post(url, data, config);
    const timelineEvents = response.data.timelineEvents;

    // console.log(timelineEvents)
    const reportsCollection: any = timelineEvents.map((event: any) => ({
      // dateString: event.dateString,
      date: new Date(event.date),
      tags: event.tags,
      authorId: event.userID,
      uuid: event.dataID,
      title: event.title,
      files: event.timelineFiles.map(
        (file: any): FileInterface => ({
          dataUrl: file.dataUrl,
          thumbnailUrl: file.thumbnailUrl,
          fileMimeType:
            file.mime.toLowerCase() === "application/pdf"
              ? MimeType.PDF
              : MimeType.NON_PDF,
          name: file.name,
        })
      ),
      labCode: event.labCode,
      labName: event.labName,
      isLab: event.isLab,
    }));
    return reportsCollection;
  } catch (error) {
    throw error;
  }
};

// export const retrieveDataUsingToken = () => {
//   return new Promise((resolve, reject) => {
//     let timelineData: any = [];
//     const timelineStream = APIClient.hms.streamTimeline({});
//     timelineStream.on("data", (timelineEvent: any) => {
//       const date = new Date(
//         timelineEvent.getMm() +
//           "/" +
//           timelineEvent.getDd() +
//           "/" +
//           timelineEvent.getYyyy()
//       );
//       let timelineEventParsed = {
//         date,
//         tags: timelineEvent.getTagsList(),
//         authorId: timelineEvent.getUpdatedbyid(),
//         timelineId: timelineEvent.getTimelineid(),
//         uuid: timelineEvent.getDataid(),
//         title: timelineEvent.getTitle(),
//         files: timelineEvent.getTimelinefilesList().map(
//           (file: any): FileInterface => ({
//             dataUrl: file.getDataurl(),
//             thumbnailUrl: file.getThumbnailurl(),
//             fileMimeType:
//               file.getMime().toLowerCase() === "application/pdf"
//                 ? MimeType.PDF
//                 : MimeType.NON_PDF,
//             name: file.getName(),
//           })
//         ),
//       };
//       timelineData.push(timelineEventParsed);
//     });
//     timelineStream.on("end", () => {
//       resolve(timelineData);
//     });
//     timelineStream.on("error", (e: any) => {
//       console.log(e);
//       reject(e);
//     });
//   });
// };

// export const retrieveDataUsingToken = async () => {
//   try {
//     const timelineStream = await axios.post(
//       `${GRPC_ENDPOINT}/docinterface.DocAPI/streamTimeline`,
//       {},
//       {
//         headers: {
//           token: localStorage.getItem("AUTH_TOKEN"),
//           "X-User-Agent": "grpc-web-javascript/0.1",
//           "X-Grpc-Web": "1",
//           // "User-Agent":
//           //   "Mozilla/5.0 (Linux; Android 11.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Mobile Safari/537.36",
//           // "Sec-Fetch-Mode": "cors",
//           // "Sec-Fetch-Site": "cross-site",
//           // "Sec-Fetch-Dest": "empty",
//           // Origin: "https://records.farmako.me",
//           // Referer: "https://records.farmako.me/",
//           // "sec-ch-ua-platform": "Android",
//           // "sec-ch-ua-mobile": "?1",
//           // "sec-ch-ua":
//           // 'Google Chrome";v="105", "Not)A;Brand";v="8", "Chromium";v="105"',
//           client: "Server",
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           "Accept-Language": "en-US,en;q=0.9",
//         },
//       }
//     );
//     let timelineData: any = [];
//     for (let i = 0; i < timelineStream.data._incoming.length; i++) {
//       const timelineEvent = timelineStream.data._incoming[i];

//       const files: any = [];
//       if (timelineEvent.timelineFiles) {
//         for (let j = 0; j < timelineEvent.timelineFiles.length; j++) {
//           const file = timelineEvent.timelineFiles[j];
//           files.push({
//             dataUrl: file.dataUrl,
//             thumbnailUrl: file.thumbnailUrl,
//             fileMimeType:
//               file.mime.toLowerCase() === "application/pdf"
//                 ? MimeType.PDF
//                 : MimeType.NON_PDF,
//             name: file.name,
//           });
//         }
//         const parsed_data = {
//           date: new Date(
//             timelineEvent.mm + "/" + timelineEvent.dd + "/" + timelineEvent.yyyy
//           ),
//           tags: timelineEvent.tags,
//           authorId: timelineEvent.userID,
//           timelineId: timelineEvent.timelineID,
//           uuid: timelineEvent.dataID,
//           title: timelineEvent.title,
//           files: files,
//           labCode: timelineEvent.labCode,
//           labName: timelineEvent.labName,
//           isLab: timelineEvent.isLab,
//         };
//         timelineData.push(parsed_data);
//       }
//     }
//     return timelineData;
//   } catch (e) {
//     console.log(e);
//   }
// };

export const retrieveDataUsingToken = async (page = 1, limit = 10) => {
  const url = `${GRPC_ENDPOINT}/docinterface.DocAPI/getTimeline?page=${page}&limit=${limit}`;

  try {
    const { data } = await axios.post(url, undefined, {
      headers: {
        token: localStorage.getItem("AUTH_TOKEN"),
        "X-User-Agent": "grpc-web-javascript/0.1",
        "X-Grpc-Web": "1",
        client: "Server",
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "en-US,en;q=0.9",
      },
      transformResponse: (data) => {
        data = JSON.parse(data);
        data.timelineEvents = data.timelineEvents.map((e: any) => ({
          ...e,
          date: new Date(e.date),
          files: e.timelineFiles.map(
            (file: any): FileInterface => ({
              dataUrl: file.dataUrl,
              thumbnailUrl: file.thumbnailUrl,
              fileMimeType:
                file.mime.toLowerCase() === "application/pdf"
                  ? MimeType.PDF
                  : MimeType.NON_PDF,
              name: file.name,
            })
          ),
        }));
        return data;
      },
    });
    return data;
  } catch (e) {
    console.error(e);
  }
};

const initialUser: User = {
  patientName: "Farmako User",
  phoneNumber: "1234567890",
  jwt: "",
};

// const exampleReportsCollection = {
//     reports:[]
// }

export const initialState = {
  user: initialUser,
  reportsCollection: { reports: [] },
  selectedReports: [],
  mode: Mode.VIEWING,
  popup: false,
  uploadedFiles: [],
};
