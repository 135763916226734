import { combineReducers } from "redux";
import patientReducer from "./patientReducer";
import profile from "./profile";

const reducers = combineReducers({
  patient: patientReducer,
  profile: profile,
});

export default reducers;
