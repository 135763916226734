import { toast } from "react-toastify";
import * as actions from "./actionTypes";
// import {Report} from '@data/interfaces'
import { FileInterface, Report } from "../data/interfaces";
import { v4 as uuid } from "uuid";
import axios from "axios";
import { GRPC_ENDPOINT } from "../../config";

export const setReportsCollection = (fetchedReportsCollection: Report[]) => {
  return (dispatch: any) =>
    dispatch({
      type: actions.SETTING_REPORTS_COLLECTION,
      payload: { fetchedReportsCollection },
    });
};

export const toggleAddMode = () => {
  return (dispatch: any) =>
    dispatch({
      type: actions.TOGGLE_ADD_MODE,
    });
};

export const toggleSelectionMode = () => {
  return (dispatch: any) => dispatch({ type: actions.TOGGLE_SELECTION_MODE });
};

export const setPopup = (value: boolean) => {
  return (dispatch: any) =>
    dispatch({ type: actions.SET_POPUP, payload: { value } });
};

export const selectReport = (report: Report) => {
  // console.log(report)
  return (dispatch: any) =>
    dispatch({
      type: actions.SELECT_REPORT,
      payload: { report },
    });
};
export const deselectReport = (report: Report) => {
  // console.log(report)
  return (dispatch: any) =>
    dispatch({ type: actions.DESELECT_REPORT, payload: { report } });
};

export const setUser = (name: string, jwt: string) => {
  return (dispatch: any) =>
    dispatch({
      type: actions.SETTING_USER,
      payload: {
        user: { patientName: name, jwt: jwt },
      },
    });
};

export const uploadFile = (file: FileInterface) => {
  return (dispatch: any) =>
    dispatch({
      type: actions.ADDING_FILE,
      payload: { file: { ...file, uuid: uuid() } },
    });
};

export const removeUploadedFile = (uuid: string) => {
  return (dispatch: any) =>
    dispatch({
      type: actions.REMOVE_UPLOADED_FILE,
      payload: { uuid },
    });
};

export const clearUploadedFiles = () => {
  return (dispatch: any) =>
    dispatch({
      type: actions.CLEAR_UPLOADED_FILES,
    });
};

export const uploadRecord =
  (fd: any, report: Report) => async (dispatch: any) => {
    await axios.post(`${GRPC_ENDPOINT}/docinterface.DocAPI/uploadReport`, fd, {
      headers: {
        client: "User",
        token: localStorage.getItem("AUTH_TOKEN"),
      },
    });

    dispatch({
      type: actions.UPLOAD_REPORT,
      payload: { report },
    });
  };

export const deleteReport =
  (patientId: string, reports: Report | Report[]) => async (dispatch: any) => {
    const reportsArray = Array.isArray(reports) ? reports : [reports];

    const id = toast.loading(
      `Deleting Report${reportsArray.length > 1 ? "s" : ""}`
    );

    for (let index = 0; index < reportsArray.length; index++) {
      const report = reportsArray[index];

      await axios.post(
        `${GRPC_ENDPOINT}/docinterface.DocAPI/upsertRecord`,
        {
          patientId,
          timelineId: report.timelineId,
          deleteRecord: true,
        },
        {
          headers: {
            client: "User",
            token: localStorage.getItem("AUTH_TOKEN"),
          },
        }
      );
    }

    toast.update(id, {
      render: `Report${reportsArray.length > 1 ? "s" : ""} Deleted!`,
      type: "success",
      isLoading: false,
      autoClose: null,
      closeButton: null,
    });

    dispatch({
      type: actions.DELETE_REPORT,
      payload: { reports: reportsArray },
    });
  };
