// Setting the app state with data from the backend
export const SETTING_USER = "setting-user";
export const SETTING_REPORTS_COLLECTION = "setting-reports-collecion";

// Related to changing the UI based on some action
export const TOGGLE_ADD_MODE = "toggle-add-mode";
export const TOGGLE_SELECTION_MODE = "toggle_selection-mode";
export const SET_POPUP = "set-popup";

// Related to selecting some report(s) and then sharing and deleting them
export const SELECT_REPORT = "select-report";
export const DESELECT_REPORT = "deselect-report";

// Related to uploading a new report
export const ADDING_FILE = "adding-file";
export const REMOVE_UPLOADED_FILE = "remove-uploaded-file";
export const CLEAR_UPLOADED_FILES = "clear-uploaded-files";
export const UPLOAD_REPORT = "upload-report";
export const DELETE_REPORT = "delete-report";

// Auth
export const LOGIN_USER = "login-user";
export const LOGOUT_USER = "logout-user";
export const SEND_OTP = "send-otp";
export const REGISTER = "register";
export const UPDATE_USER = "update-user";
