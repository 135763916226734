import { useEffect } from "react";
import { bindActionCreators } from "redux";
import * as actionCreators from "@actions/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@redux/store";

function PopupClose() {
  const popup = useSelector((state: any) => state.patient.popup);

  const dispatch: AppDispatch = useDispatch();
  const AC = bindActionCreators(actionCreators, dispatch);
  const { setPopup } = AC;

  useEffect(() => {
    dispatch(setPopup(false));
  }, []);

  return popup ? (
    <div
      className="fixed top-0 left-0 w-full h-full"
      onClick={() => dispatch(setPopup(false))}
      style={{
        zIndex: 1,
        // backgroundColor: "rgba(255,0,0,0.5)",
      }}
    ></div>
  ) : null;
}

export default PopupClose;
