import * as actions from "@actions/actionTypes";

const profile = (state: any = {}, { type, payload }: any) => {
  switch (type) {
    // Setting the app state with data from the backend
    case actions.SETTING_USER:
      return {
        profiles: {
          name: payload.patientName,
        },
      };
    case actions.LOGIN_USER:
      return {
        isLoggedIn: true,
        profiles: payload.profile,
        token: payload.token,
        newUser: payload.newUser,
      };
    case actions.LOGOUT_USER:
      return {
        isLoggedIn: false,
        profiles: {},
        token: "",
        newUser: true,
      };
    case actions.REGISTER:
      return {
        isLoggedIn: true,
        profiles: payload.profile,
        token: payload.token,
        newUser: false,
      };
    case actions.UPDATE_USER:
      return {
        ...state,
        profiles: {
          ...state.profiles,
          ...payload.user,
        },
      };
    default:
      // console.log('default case triggered')
      return state;
  }
};

export default profile;
